import React, { useState } from 'react';
import moment from 'moment';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Chip, MenuItem, Typography } from '@mui/material';
import { MetaData } from '@tphglobal/common/models/baseEntities';
import {
  brand,
  colors,
  greyScaleColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import messages from '../messages';
import {
  StyledActionItem,
  StyledActionListContainer,
  StyledArrowDropIcon,
  StyledCellContainer,
  StyledInfo,
  StyledNoDataInfo,
  StyledNoDataInfoContainer,
  StyledPageContainer,
  StyledPagesContainer,
  StyledPaginationContainer,
  StyledPaginationLimitContainer,
  StyledPaginationMainContainer,
  StyledPaginationShowContainer,
  StyledSelectPage,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
} from './styles';
import { Status } from '@tphglobal/common/models/modules';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { FixedTitle } from '@tphglobal/common/utils/theme';

interface SortObject {
  key: string;
  direction: 'asc' | 'desc';
}

function sortList<T>(data: T[], sortObject: { key: string; direction: 'asc' | 'desc' }): T[] {
  const { key, direction } = sortObject;
  
  return data.sort((a: any, b: any) => {
    let valueA;
    let valueB;
    if(key === 'Company') {
      valueA = a?.Company[0]?.Name
      valueB = b?.Company[0]?.Name
    } else {
      valueA = a[key];
      valueB = b[key];
    }
    
    if (key === 'Date') {
      if (direction === 'asc') {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    }

    if (valueA < valueB) {
      return direction === 'asc' ? -1 : 1;
    } else if (valueA > valueB) {
      return direction === 'asc' ? 1 : -1;
    } else {
      return 0;
    }
  });
}


export const formatStr = (str: string): string => str;
export const formatDate = (str: string): string =>
  str ? moment(str).format('DD MMM YYYY') : '';
export const formatStatus = (row: any) => (
  <Chip
    label={`${row?.status.charAt(0).toUpperCase()}${row?.status.slice(1)}`}
    sx={{
      backgroundColor:
        row?.status === Status.active
          ? otherColour.successBg
          : otherColour.errorBg,
      borderRadius: '4px',
      padding: '4px 8px',
      color:
        row?.status === Status.active
          ? otherColour.successDefault
          : otherColour.errorDefault,
    }}
  />
);

const paginationLimitOpts = [10, 20, 50];

export interface TableSpec {
  id: string;
  label?: string;
  minWidth?: string | number;

  format?(val: any, fieldKey ?: any, rowIndex ?: any): JSX.Element | string;
  editableFieldInput?(val : any): JSX.Element | string

  getValue?(row: any): any;
}

export interface ActionSpec {
  id: string;
  component?: JSX.Element;
  render?: (row: any) => JSX.Element;
  onClick(row: any,event?: React.MouseEvent, rowIndex ?: any): void;
  renderAction?: (row: any) => void;
}

export interface TableProps {
  specs: TableSpec[];
  data: Record<string, any>[];
  metadata?: MetaData<any>;
  emptyMessage?: string;
  disableSorting?: string[];
  disableTableSorting?: boolean;
  actions?: ActionSpec[];
  actionLabel?: string;
  showIndex?: boolean
  editableFields?: string[]
  initialSortColumn?: string

  renderColumn?(column: string): boolean;

  updateFilters?(param: Partial<MetaData<any>>): void;

  getId?(param: Record<string, any>): any;

  fetchPage?(page?: number): void;
  updateLimit?(limit?: number): void;
  handleSort?: (id: string) => void;
  dragItem?: React.MutableRefObject<any>;
  dragOverItem?: React.MutableRefObject<any>;
}

export const ActionMenu: React.FC<{
  actions: ActionSpec[];
  row: Record<string, any>;
  rowIndex ?: any
}> = ({ actions, row, rowIndex }) => {

  const handleClick = (e: React.MouseEvent, actionClick?: (row: Record<string, any>, event: React.MouseEvent, rowIndex : any) => void) => {
    if (actionClick) {
      actionClick(row, e, rowIndex);
    }
  };

  return (
    <StyledActionListContainer>
      {actions
        .filter((action) =>
          action?.renderAction ? action?.renderAction(row) : true
        )
        .map((action) => (
          <StyledActionItem
            key={action.id}
            onClick={(e) => handleClick(e, action.onClick)}
          >
            {action.component}
            {action.render?.(row)}
          </StyledActionItem>
        ))}
    </StyledActionListContainer>
  );
};

const Table: React.FC<TableProps> = ({
  data,
  specs,
  metadata,
  disableSorting,
  actions,
  emptyMessage,
  disableTableSorting,
  actionLabel,
  renderColumn,
  getId,
  fetchPage,
  updateLimit,
  initialSortColumn,
  showIndex,
  editableFields,
}) => {
  const shouldRenderColumn = (column: string): boolean =>
    !renderColumn || renderColumn(column);
  const [sortObject, setSortObject] = useState<SortObject>({
    key : initialSortColumn,
    direction : 'asc'
  })
  const hasActions = () => actions && actions?.length > 0;

  const getRowId = (row: Record<string, any>) => (getId ? getId(row) : row.id);
  
  const iconStyles = {
    height: fontSize?.b1,
    width: fontSize?.b1,
    color: greyScaleColour?.black100,
  };
  const titles = () => {
    const clickTitle = (spec: TableSpec) => {
      if (
        !disableTableSorting &&
        !disableSorting?.includes?.(spec.id)
      ) {
        setSortObject({
          key : spec.id,
          direction : sortObject?.direction === 'asc' ? 'desc' : 'asc'
        })
      }
    };

    const titleCells = specs
      .filter((spec) => shouldRenderColumn(spec.id))
      .map((spec) => {
        const canSort =
          !disableTableSorting && !disableSorting?.includes?.(spec.id);
        const showIcon = canSort && sortObject?.key === spec.id;
        return (
          <StyledTableCell
            key={spec.label || `_id_${spec.id}`}
            onClick={() => clickTitle(spec)}
            style={{
              minWidth: spec?.minWidth || 'auto',
              display: spec?.id === 'draggable' && 'none',
              cursor: !disableSorting?.includes?.(spec.id) && 'pointer'
            }}
          >
            <StyledCellContainer>
              <FixedTitle varient='Fixed14Bold'>{spec.label || ''}</FixedTitle>
              {showIcon &&
                (sortObject?.direction === 'asc' ? (
                  <ArrowDownwardIcon style={iconStyles} />
                ) : (
                  <ArrowUpwardIcon style={iconStyles} />
                ))}
            </StyledCellContainer>
          </StyledTableCell>
        );
      });

    if (showIndex) {
      titleCells.unshift(
        <StyledTableCell key='index'>
          <FixedTitle varient='Fixed14Bold'>{''}</FixedTitle>
        </StyledTableCell>
      );
    }

    return titleCells.concat(
      hasActions()
        ? [<StyledTableCell key={'actions'}>{actionLabel}</StyledTableCell>]
        : []
    );
  };
  const fields = () => {
    sortList(data, sortObject);

    let immutableData = [...data];
    specs.forEach((spec) => {
      if (spec.getValue) {
        immutableData = immutableData.map((row: Record<string, any>) => ({
          ...row,
          [spec.id]: spec.getValue?.(row),
        }));
      }
    });
    Object.freeze(immutableData);
    return immutableData.map((row: Record<string, any>, index: number) => (
      
      <StyledTableRow
        key={getRowId(row)}
        draggable={!!row?.draggable}
      >
        {showIndex && (
          <StyledTableCell key={`index-${index}`} width='25px'>
            {metadata && (metadata.page - 1) * metadata.limit + index + 1}.
          </StyledTableCell>
        )}
        {specs
          .filter((spec) => shouldRenderColumn(spec.id))
          .map((field) => {
            const formatter = (param: any, fieldKey ?: string) =>
              field.format ? field.format(param, fieldKey, index) : formatStr(param);
            

            const isEditable = editableFields?.includes(field?.id);

            return (
              <StyledTableCell
                key={`${field.label}@${field.id}`}
                style={{
                  minWidth: field?.minWidth || 'auto',
                  wordBreak: 'break-word',
                  display: field?.id === 'draggable' && 'none',
                  width: field?.id === 'drag' && '70px',
                  cursor: isEditable  && 'pointer'
                }}
              >
                {formatter(row[field.id],field.id)}
              </StyledTableCell>
            );
          })}
        {hasActions() && (
          <StyledTableCell key='actionButtons'>
            <ActionMenu actions={actions} row={row} rowIndex={index} />
          </StyledTableCell>
        )}
      </StyledTableRow>
    ));
  };
  const pagination = () => {
    const pageCount = Math.ceil(metadata?.total / metadata?.limit);
    const pages = [];

    pages.push(
      <ChevronLeftRoundedIcon
        style={{
          color: brand.secondaryMain,
          cursor: 'pointer',
        }}
        onClick={() => {
          if (pageCount > 1) {
            fetchPage(metadata?.page - 1);
          }
        }}
      />
    );
    // for (let i = 1; i <= pageCount; i += 1) {
    //   pages.push(
    //     <StyledPageContainer
    //       active={i === metadata?.page}
    //       onClick={() => fetchPage(i)}
    //     >
    //       {i}
    //     </StyledPageContainer>
    //   );
    // }

    pages.push(
      <ChevronRightRoundedIcon
        style={{
          color: brand.secondaryMain,
          cursor: 'pointer',
        }}
        onClick={() => {
          if (pageCount !== metadata?.page) {
            fetchPage(metadata?.page + 1);
          }
        }}
      />
    );

    return pages;
  };

  return (
    <StyledTableContainer>
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow>{titles()}</StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>{[fields()]}</StyledTableBody>
      </StyledTable>
      {data.length === 0 && (
        <StyledNoDataInfoContainer>
          <FixedTitle varient='Fixed14Regular' color={greyScaleColour?.grey100}>
            {emptyMessage || messages?.general?.noData}
          </FixedTitle>
        </StyledNoDataInfoContainer>
      )}
      {data.length !== 0 && fetchPage && (
        <StyledPaginationContainer>
          <StyledPaginationMainContainer>
            <StyledPaginationLimitContainer>
              <StyledInfo variant='body1'>
                {messages?.general?.showing}
              </StyledInfo>
              <StyledSelectPage
                IconComponent={StyledArrowDropIcon}
                value={metadata?.limit}
                onChange={(event: any) => {
                  if (updateLimit) {
                    updateLimit(event?.target?.value);
                  }
                }}
              >
                {paginationLimitOpts?.map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
              </StyledSelectPage>
            </StyledPaginationLimitContainer>
            <StyledPaginationShowContainer>
              <Typography variant='body1'>1-10 of 324</Typography>
            </StyledPaginationShowContainer>
            <StyledPagesContainer>{pagination()}</StyledPagesContainer>
          </StyledPaginationMainContainer>
        </StyledPaginationContainer>
      )}
    </StyledTableContainer>
  );
};

export default React.memo(Table);
