import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Form,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  OutlinedContainer,
  TextInput,
  TimeInput,
} from '@tphglobal/components';
import { brand, greyScaleColour } from '@tphglobal/common/theme/style.palette';
import { FixedTitle, mapFlagToValue } from '@tphglobal/common/utils';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import CustomCheckbox from '@tphglobal/components/checkBoxInput';
import moment from 'moment';
import { Option } from '@tphglobal/common/models';
import {
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledAccordion,
} from './styles';
import {
  CompanyDetailedEntity,
  CustomField,
  DailyTime,
  Payas,
  TimeOption,
  Validators,
} from '../../models';
import { required } from '../../utils';
import CustomFieldComponent from './customFieldComponent';
import { formatTime, toCamelCase } from '../../utils/commonFunctions';
import { ReduxState } from '../../redux/reducers';
import messages from '../../messages';
import timeInputField from './tableInputs/timeInputField';

interface FormData {
  StartMeal: Date;
  StartMeal2: Date;
  StartMeal3: Date;
  FinishMeal1: Date;
  FinishMeal3: Date;
  FinishMeal2: Date;
  MB1ND: boolean;
  MB2ND: boolean;
  MB3ND: boolean;
  Start: Date;
  Finish: Date;
  Payas: {id : string, label : string};
  Travel1: string;
  Note: string | null;
  Travel2: string;
  TotalMBDed: string;
}

const expandIconStyles = {
  height: fontSize?.h3,
  width: fontSize?.h3,
  fill: brand?.secondaryMain,
};

interface PassedData {
  data : DailyTime,
  rowIndex : number
}

interface Props {
  onCancel: () => void;
  onSuccess: (data: DailyTime) => void;
  passedData: PassedData;
  companyDetails?: CompanyDetailedEntity;
  yesterdayTimings?: DailyTime;
  setYesterdayTimings ?: (yesterdayTimings : DailyTime | null) => void
}

let validators : Validators = {
  Payas: [required(messages?.general?.validations?.payAsRequired)],
  Note: [required(messages?.general?.validations?.noteRequired)],
};

const UpdateTimeForm: React.FC<Props> = ({
  onCancel,
  onSuccess,
  passedData,
  companyDetails,
  yesterdayTimings,
  setYesterdayTimings,
}) => {
  const data: DailyTime = passedData?.data;
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const [submitError, setSubmitError] = useState<string>('');
  const [useMBValues, setUseMBValues] = useState<boolean>(false)
  
  const {
    handleSubmit, connectField, change, formValues,
  } = useFormReducer(validators);

  const getTotalHours = () => {
    if (formValues?.Payas?.value?.id === 'DNW') {
      return '00:00';
    }
    const startMB1 = moment(formValues?.StartMeal?.value, 'HH:mm');
    const startMB2 = moment(formValues?.StartMeal2?.value, 'HH:mm');
    const startMB3 = moment(formValues?.StartMeal3?.value, 'HH:mm');
    const finishMB1 = moment(formValues?.FinishMeal1?.value, 'HH:mm');
    const finishMB2 = moment(formValues?.FinishMeal2?.value, 'HH:mm');
    const finishMB3 = moment(formValues?.FinishMeal3?.value, 'HH:mm');

    const startWorkTime = moment(formValues?.Start?.value, 'HH:mm');
    const finishWorkTime = moment(formValues?.Finish?.value, 'HH:mm');

    if (finishWorkTime.isBefore(startWorkTime)) {
      finishWorkTime.add(1, 'day');
    }
    const travelToTime = moment(formValues?.Travel1?.value, 'HH:mm');
    const travelFromTime = moment(formValues?.Travel2?.value, 'HH:mm');
    const defaultMealBreakTime = moment(formValues?.TotalMBDed?.value, 'HH:mm');

    const calculateMealBreakDuration = () => {
      const totalMealBreakDuration = moment.duration(0);

      if (
        startMB1.isValid()
        && finishMB1.isValid()
        && finishMB1.isAfter(startMB1)
      ) {
        totalMealBreakDuration.add(moment.duration(finishMB1.diff(startMB1)));
      }
      if (
        startMB2.isValid()
        && finishMB2.isValid()
        && finishMB2.isAfter(startMB2)
      ) {
        totalMealBreakDuration.add(moment.duration(finishMB2.diff(startMB2)));
      }
      if (
        startMB3.isValid()
        && finishMB3.isValid()
        && finishMB3.isAfter(startMB3)
      ) {
        totalMealBreakDuration.add(moment.duration(finishMB3.diff(startMB3)));
      }

      if (totalMealBreakDuration.asMilliseconds() > 0) {
        if (formValues?.MB1ND?.value) {
          totalMealBreakDuration.subtract(
            moment.duration(finishMB1.diff(startMB1)),
          );
        }
        if (formValues?.MB2ND?.value) {
          totalMealBreakDuration.subtract(
            moment.duration(finishMB2.diff(startMB2)),
          );
        }
        if (formValues?.MB3ND?.value) {
          totalMealBreakDuration.subtract(
            moment.duration(finishMB3.diff(startMB3)),
          );
        }

        return totalMealBreakDuration;
      }
      return moment.duration(
        defaultMealBreakTime.diff(moment().startOf('day')),
      );
    };

    const mealBreakDuration = calculateMealBreakDuration();

    if (startWorkTime.isValid() && finishWorkTime.isValid()) {
      if (finishWorkTime.isBefore(startWorkTime)) {
        return '00:00';
      }

      const totalWorkDuration = moment.duration(
        finishWorkTime.diff(startWorkTime),
      );

      if (
        mealBreakDuration.asMilliseconds() >= totalWorkDuration.asMilliseconds()
      ) {
        return '00:00';
      }

      let netDuration = totalWorkDuration.subtract(mealBreakDuration);

      if (!companyDetails?.TravelToHide) {
        const travelToDuration = moment.duration(
          travelToTime.diff(moment().startOf('day')),
        );
        netDuration = netDuration.add(travelToDuration);
      }

      if (!companyDetails?.TravelFromHide) {
        const travelFromDuration = moment.duration(
          travelFromTime.diff(moment().startOf('day')),
        );
        netDuration = netDuration.add(travelFromDuration);
      }

      const totalHours = moment
        .utc(netDuration.asMilliseconds())
        .format('HH:mm');
      return totalHours;
    }

    return '00:00';
  };

  const onSubmit = async (data: FormData) => {
    const startMB1 = moment(data?.StartMeal || '00:00', 'HH:mm');
    const startMB2 = moment(data?.StartMeal2 || '00:00', 'HH:mm');
    const startMB3 = moment(data?.StartMeal3 || '00:00', 'HH:mm');
    const finishMB1 = moment(data?.FinishMeal1 || '00:00', 'HH:mm');
    const finishMB2 = moment(data?.FinishMeal2 || '00:00', 'HH:mm');
    const finishMB3 = moment(data?.FinishMeal3 || '00:00', 'HH:mm');
    const defaultMealBreakTime = moment(data?.TotalMBDed || '00:00', 'HH:mm');

    const calculateMealBreakDuration = (): moment.Duration | undefined => {
      const totalMealBreakDuration = moment.duration(0);
      let accordionUsed = false;

      if (startMB1.isAfter(finishMB1)) {
        accordionUsed = true;
        setSubmitError('End meal break 1 cannot be before start meal break 1');
        return undefined;
      }
      if (startMB2.isAfter(finishMB2)) {
        accordionUsed = true;
        setSubmitError('End meal break 2 cannot be before start meal break 2');
        return undefined;
      }
      if (startMB3.isAfter(finishMB3)) {
        accordionUsed = true;
        setSubmitError('End meal break 3 cannot be before start meal break 3');
        return undefined;
      }

      return accordionUsed
        ? totalMealBreakDuration
        : moment.duration(defaultMealBreakTime.diff(moment().startOf('day')));
    };

    const mealBreakTime = calculateMealBreakDuration();
    const startWorkTime = moment(data?.Start || '00:00', 'HH:mm');
    const finishWorkTime = moment(data?.Finish || '00:00', 'HH:mm');
    const travelToTime = moment(data?.Travel1 || '00:00', 'HH:mm');
    const travelFromTime = moment(data?.Travel2 || '00:00', 'HH:mm');

    if (
      startWorkTime?.isValid()
      && finishWorkTime?.isValid()
      && mealBreakTime?.isValid()
    ) {
      if (finishWorkTime.isBefore(startWorkTime)) {
        finishWorkTime.add(1, 'day');
      }

      const totalWorkDuration = moment.duration(
        finishWorkTime.diff(startWorkTime),
      );

      if (mealBreakTime.asMinutes() > totalWorkDuration.asMinutes()) {
        setSubmitError(
          'Meal break cannot be longer than the total work duration',
        );
        return;
      }
    } else {
      if (!startWorkTime?.isValid()) {
        setSubmitError('Invalid start work time');
      } else if (!finishWorkTime?.isValid()) {
        setSubmitError('Invalid finish work time');
      } else if (!mealBreakTime?.isValid()) {
        setSubmitError('Invalid meal break time');
      } else if (!travelToTime?.isValid() && companyDetails?.TravelToHide) {
        setSubmitError('Invalid travel to time');
      } else if (!travelFromTime?.isValid() && companyDetails?.TravelFromHide) {
        setSubmitError('Invalid travel from time');
      } else if (!startMB1?.isValid() || !finishMB1?.isValid()) {
        setSubmitError('Invalid start or finish meal break 1 time');
      } else if (!startMB2?.isValid() || !finishMB2?.isValid()) {
        setSubmitError('Invalid start or finish meal break 2 time');
      } else if (!startMB3?.isValid() || !finishMB3?.isValid()) {
        setSubmitError('Invalid start or finish meal break 3 time');
      }
      return;
    }
    setSubmitError('');

    const updatedDayData: DailyTime = { ...passedData?.data };

    Object.keys(data).forEach((key: keyof FormData) => {
      if (key === 'Note') {        
        updatedDayData[key] = data[key];
      } else if (key === 'Payas') {
        updatedDayData[key] = data[key]?.label;
      } else if (key === 'MB1ND' || key === 'MB2ND' || key === 'MB3ND') {
        updatedDayData[key] = data[key] ? '1' : '0';
      } else {
        updatedDayData[key] = moment(data[key]).format('HH:mm');
      }
    });

    (updatedDayData?.CustomTimeFields || []).forEach(
      (timeField: CustomField, index: number) => {
        const key = toCamelCase(timeField?.Prompt);

        if (key in data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updatedDayData.CustomTimeFields[index].Value = (data as { [key: string]: any })[key];
        }
      },
    );

    updatedDayData.customNote = `${data?.Payas?.label || data?.Payas} ${
      data?.Note || ''
    }`;
    updatedDayData.TotalHours = getTotalHours();

    onSuccess(updatedDayData);
  };
  useEffect(() => {
    change(
      'Payas',
      data?.Payas !== '0' && {
        id: companyDetails?.Payas?.find(
          (opt: Payas) => opt?.Value === data?.Payas,
        )?.Flag,
        label: data?.Payas,
      },
    );
    change('Start', moment(data?.Start, 'HH:mm'));
    change('TotalMBDed', moment(data?.TotalMBDed, 'HH:mm'));
    change('Finish', moment(data?.Finish, 'HH:mm'));
    change('StartMeal', moment(data?.StartMeal, 'HH:mm'));
    change('StartMeal2', moment(data?.StartMeal2, 'HH:mm'));
    change('StartMeal3', moment(data?.StartMeal3, 'HH:mm'));
    change('FinishMeal1', moment(data?.FinishMeal1, 'HH:mm'));
    change('FinishMeal2', moment(data?.FinishMeal2, 'HH:mm'));
    change('FinishMeal3', moment(data?.FinishMeal3, 'HH:mm'));
    change('Travel1', moment(data?.Travel1, 'HH:mm'));
    change('Travel2', moment(data?.Travel2, 'HH:mm'));
    change('MB1ND', data?.MB1ND === '1');
    change('MB2ND', data?.MB2ND === '1');
    change('MB3ND', data?.MB3ND === '1');
    change('MB3ND', data?.MB3ND === '1');
    change('Note', data?.Note);

    if (passedData?.data?.CustomTimeFields?.length > 0) {
      passedData?.data?.CustomTimeFields.forEach((field: CustomField) => {
        change(toCamelCase(field?.Prompt), field?.Value);
      });
    }

    const newValidators: Validators = { ...validators };
    passedData?.data?.CustomTimeFields?.forEach((field: CustomField) => {
      if (field?.required === 1) {
        newValidators[toCamelCase(field?.Prompt)] = [
          required('Field cannot be empty, please enter some value.'),
        ];
      }
    });
    validators = newValidators;
  }, []);

  useEffect(() => {
    if (yesterdayTimings) {
      change(
        'Payas',
        yesterdayTimings?.Payas !== '0' && {
          id: companyDetails?.Payas?.find(
            (opt: Payas) => opt?.Value === yesterdayTimings?.Payas,
          )?.Flag,
          label: yesterdayTimings?.Payas,
        },
      );
      change('Start', moment(yesterdayTimings?.Start, 'HH:mm'));
      change('TotalMBDed', moment(yesterdayTimings?.TotalMBDed, 'HH:mm'));
      change('Finish', moment(yesterdayTimings?.Finish, 'HH:mm'));
      change('StartMeal', moment(yesterdayTimings?.StartMeal, 'HH:mm'));
      change('StartMeal2', moment(yesterdayTimings?.StartMeal2, 'HH:mm'));
      change('StartMeal3', moment(yesterdayTimings?.StartMeal3, 'HH:mm'));
      change('FinishMeal1', moment(yesterdayTimings?.FinishMeal1, 'HH:mm'));
      change('FinishMeal2', moment(yesterdayTimings?.FinishMeal2, 'HH:mm'));
      change('FinishMeal3', moment(yesterdayTimings?.FinishMeal3, 'HH:mm'));
      change('Travel1', moment(yesterdayTimings?.Travel1, 'HH:mm'));
      change('Travel2', moment(yesterdayTimings?.Travel2, 'HH:mm'));
      change('MB1ND', yesterdayTimings?.MB1ND === '1');
      change('MB2ND', yesterdayTimings?.MB2ND === '1');
      change('MB3ND', yesterdayTimings?.MB3ND === '1');
      change('MB3ND', yesterdayTimings?.MB3ND === '1');
      change('Note', yesterdayTimings?.Note);
      setYesterdayTimings(null)
    }
  }, [yesterdayTimings]);

  useEffect(() => {
    getTotalHours();
  }, [formValues?.Start, formValues?.Finish, formValues?.TotalMBDed]);

  useEffect(() => {    
    const hasValidMealTime = [
      formValues?.StartMeal?.value, 
      formValues?.StartMeal2?.value,
      formValues?.StartMeal3?.value,
      formValues?.FinishMeal1?.value,
      formValues?.FinishMeal2?.value,
      formValues?.FinishMeal3?.value
    ].some(value => value && moment(value).format('HH:mm') !== '00:00');

    if (hasValidMealTime) {
      const startMoment1 = moment(formValues?.StartMeal?.value);
      const finishMoment1 = moment(formValues?.FinishMeal1?.value);
      const startMoment2 = moment(formValues?.StartMeal2?.value);
      const finishMoment2 = moment(formValues?.FinishMeal2?.value);
      const startMoment3 = moment(formValues?.StartMeal3?.value);
      const finishMoment3 = moment(formValues?.FinishMeal3?.value);
  
      const diff1 = finishMoment1.diff(startMoment1);
      const diff2 = finishMoment2.diff(startMoment2);
      const diff3 = finishMoment3.diff(startMoment3);
      
      const totalMealTimeMili : number = [diff1, diff2, diff3].reduce((total, diff) => diff > 0 ? total + diff : total, 0);
      const formattedMealTime : string = formatTime(totalMealTimeMili);

      if(totalMealTimeMili >= 0) {
        change('TotalMBDed', moment(formattedMealTime, 'HH:mm'));
        setUseMBValues(totalMealTimeMili !== 0)
      }
    }
  }, [
    formValues?.StartMeal?.value,
    formValues?.StartMeal2?.value,
    formValues?.StartMeal3?.value,
    formValues?.FinishMeal1?.value,
    formValues?.FinishMeal2?.value,
    formValues?.FinishMeal3?.value
  ]);
  
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container padding="0 24px">
        <FormRow marginBottom="12px" columnSpacing={2} minWidth="868px">
          <FormRowItem>
            {connectField('Payas', {
              label: messages?.timeCard?.updateTime?.payAs,
              options: companyDetails?.Payas?.map(mapFlagToValue),
            })(MaterialAutocompleteInput)}
          </FormRowItem>
          {!companyDetails?.TravelToHide && (
            <FormRowItem>
              {connectField('Travel1', {
                label: messages?.timeCard?.updateTime?.travelTo,
                disabled:
                  formValues?.Payas?.value?.id === 'DNW',
                  CustomTimeField: timeInputField,
                disableDropDown: userProfile?.TimeOption === TimeOption.Type,
              })(TimeInput)}
            </FormRowItem>
          )}
          {!companyDetails?.TravelFromHide && (
            <FormRowItem>
              {connectField('Travel2', {
                label: messages?.timeCard?.updateTime?.travelFrom,
                CustomTimeField: timeInputField,
                disabled:
                  formValues?.Payas?.value?.id === 'DNW',
                disableDropDown: userProfile?.TimeOption === TimeOption.Type,
              })(TimeInput)}
            </FormRowItem>
          )}
        </FormRow>
        <FormRow marginBottom="4px" columnSpacing={2}>
          <FormRowItem>
            {connectField('Start', {
              label: messages?.timeCard?.updateTime?.startWork,
              CustomTimeField: timeInputField,
              disabled:
                formValues?.Payas?.value?.id === 'DNW',
              disableDropDown: userProfile?.TimeOption === TimeOption.Type,
            })(TimeInput)}
          </FormRowItem>
          <FormRowItem>
            {connectField('TotalMBDed', {
              label: messages?.timeCard?.updateTime?.mealBreak,
              CustomTimeField: timeInputField,
              disabled:
                formValues?.Payas?.value?.id === 'DNW' || useMBValues,
              disableDropDown: userProfile?.TimeOption === TimeOption.Type,
            })(TimeInput)}
          </FormRowItem>
          <FormRowItem>
            {connectField('Finish', {
              label: messages?.timeCard?.updateTime?.finishWork,
              CustomTimeField: timeInputField,
              disabled:
                formValues?.Payas?.value?.id === 'DNW',
              disableDropDown: userProfile?.TimeOption === TimeOption.Type,
            })(TimeInput)}
          </FormRowItem>
        </FormRow>
        <FormRow display="flex" justifyContent="center">
          {formValues?.Payas?.value?.id !== 'DNW' && (
          <StyledAccordion>
            <StyledAccordionSummary
              flexGrow="0"
              expandIcon={<ExpandMoreIcon style={expandIconStyles} />}
              aria-controls="australia"
              id="australia"
            >
              <FixedTitle
                varient="Fixed14Medium"
                color={brand?.secondaryMain}
              >
                {messages?.timeCard?.updateTime?.mbDetails}
              </FixedTitle>
            </StyledAccordionSummary>

            <StyledAccordionDetails>
              <OutlinedContainer
                borderRadius="8px"
                padding="16px"
                borderColor={greyScaleColour?.grey70}
              >
                <FormRow>
                  <FixedTitle
                    varient="Fixed14Regular"
                    color={brand?.secondaryMain}
                  >
                    {messages?.timeCard?.updateTime?.mealBreak}
                  </FixedTitle>
                </FormRow>
                <FormRow xs={12} spacing={2}>
                  <FormRowItem>
                    {connectField('StartMeal', {
                      label: `${messages?.timeCard?.updateTime?.startMB}1`,
                      CustomTimeField: timeInputField,
                      disableDropDown:
                            userProfile?.TimeOption === TimeOption.Type,
                    })(TimeInput)}
                  </FormRowItem>
                  <FormRowItem>
                    {connectField('FinishMeal1', {
                      label: `${messages?.timeCard?.updateTime?.finishMB}1`,
                      CustomTimeField: timeInputField,
                      disableDropDown:
                            userProfile?.TimeOption === TimeOption.Type,
                    })(TimeInput)}
                  </FormRowItem>
                  <FormRowItem display="flex" alignSelf="center">
                    {connectField('MB1ND', {
                      label: messages?.timeCard?.updateTime?.nonDed1,
                      flexDirection: 'row-reverse',
                    })(CustomCheckbox)}
                  </FormRowItem>
                </FormRow>
                <FormRow xs={12} spacing={2}>
                  <FormRowItem>
                    {connectField('StartMeal2', {
                      label: `${messages?.timeCard?.updateTime?.startMB}2`,
                      CustomTimeField: timeInputField,
                      disableDropDown:
                            userProfile?.TimeOption === TimeOption.Type,
                    })(TimeInput)}
                  </FormRowItem>
                  <FormRowItem>
                    {connectField('FinishMeal2', {
                      label: `${messages?.timeCard?.updateTime?.finishMB}2`,
                      CustomTimeField: timeInputField,
                      disableDropDown:
                            userProfile?.TimeOption === TimeOption.Type,
                    })(TimeInput)}
                  </FormRowItem>
                  <FormRowItem display="flex" alignSelf="center">
                    {connectField('MB2ND', {
                      label: messages?.timeCard?.updateTime?.nonDed2,
                      flexDirection: 'row-reverse',
                    })(CustomCheckbox)}
                  </FormRowItem>
                </FormRow>
                <FormRow xs={12} spacing={2} marginBottom={0}>
                  <FormRowItem>
                    {connectField('StartMeal3', {
                      label: `${messages?.timeCard?.updateTime?.startMB}3`,
                      CustomTimeField: timeInputField,
                      disableDropDown:
                            userProfile?.TimeOption === TimeOption.Type,
                    })(TimeInput)}
                  </FormRowItem>
                  <FormRowItem>
                    {connectField('FinishMeal3', {
                      label: `${messages?.timeCard?.updateTime?.finishMB}3`,
                      CustomTimeField: timeInputField,
                      disableDropDown:
                            userProfile?.TimeOption === TimeOption.Type,
                    })(TimeInput)}
                  </FormRowItem>
                  <FormRowItem display="flex" alignSelf="center">
                    {connectField('MB3ND', {
                      label: messages?.timeCard?.updateTime?.nonDed3,
                      flexDirection: 'row-reverse',
                    })(CustomCheckbox)}
                  </FormRowItem>
                </FormRow>
              </OutlinedContainer>
            </StyledAccordionDetails>
          </StyledAccordion>
          )}
        </FormRow>

        <FormRow>
          <FixedTitle varient="Fixed14Bold">
            {messages?.timeCard?.updateTime?.totalHours}
          </FixedTitle>
          <FixedTitle varient="Fixed14Bold">
            {getTotalHours()}
          </FixedTitle>
        </FormRow>
        <FormRow>
          {connectField('Note', {
            label: messages?.timeCard?.updateTime?.note,
            multiline: true,
            rows: 3,
            autoHeight: true,
          })(TextInput)}
        </FormRow>

        <FormRow xs={12} spacing={2} marginBottom={0}>
          {passedData?.data?.CustomTimeFields?.map((field: CustomField) => (
            <FormRowItem>
              <CustomFieldComponent field={field} connectField={connectField} />
            </FormRowItem>
          ))}
        </FormRow>
        {submitError && (
          <FormRow marginTop={2}>
            <FormRowItem>
              <FormError message={submitError} />
            </FormRowItem>
          </FormRow>
        )}
      </Grid>
      <OutlinedContainer
        noBorder
        borderRadius="0"
        padding="14px 24px"
        styles={{ backgroundColor: greyScaleColour?.grey60, marginTop: '24px' }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  buttonColor={brand?.black}
                  color="primary"
                  size="large"
                  onClick={onCancel}
                  label={messages?.general?.cancel}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  type="submit"
                  label={messages?.general?.update}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OutlinedContainer>
    </Form>
  );
};

export default UpdateTimeForm;
