import React, { useState, useEffect, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { brandColour } from '@tphglobal/common/theme/style.palette';
import moment from 'moment';
import { ReduxState } from '../../../redux/reducers';
import { DailyTime, TimeOption } from '../../../models';
import { TextInput } from '@tphglobal/components';

interface Props {
  setWeekData?: (update: (weekData: DailyTime[]) => DailyTime[]) => void;
  fieldKey?: keyof DailyTime;
  rowIndex?: number;
  value?: string;
  timeOption?: string;
  isTimeSheet?: boolean;
  onChange?: any;
  label?: string;
}

const TimePickerDropdown = styled('div')({
  border: '1px solid #ccc',
  background: 'white',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  maxHeight: '200px',
  overflowY: 'auto',
  padding: '5px 10px',
});

const TextTimeInput = styled('input')<{ isTimeSheet?: boolean }>`
  ${({ isTimeSheet }) => !isTimeSheet && `
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
    border-radius: 4px;
    background: transparent; 
    border: none; 
    max-width: 50px;
  `}
`;


const StyledMenuItem = styled(MenuItem)({
  padding: '5px 10px',
});

const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));

const timeInputField: React.FC<Props> = ({
  setWeekData, fieldKey, rowIndex, value = '00:00', timeOption, isTimeSheet = false, onChange, label,
}) => {
  const alertTimeout = useRef<NodeJS.Timeout | null>(null);
  const userProfile = useSelector((state: ReduxState) => state?.profile);
  const minutesStep = userProfile?.MinutsStep || 5;
  const minutes = Array.from({ length: 60 / minutesStep }, (_, i) => String(i * minutesStep).padStart(2, '0'));

  const [open, setOpen] = useState(false);
  const [typeFieldInput, setTypeFieldInput] = useState<string>(
    value !== "00:00" ? value : ""
  );
  
  const [selectedHour, setSelectedHour] = useState<string>(value?.split(':')[0]);
  const [selectedMinute, setSelectedMinute] = useState<string>(value?.split(':')[1]);

  const calculateTotalHours = (
    startTime: string,
    finishTime: string,
    totalMBDed: string,
  ) => {
    const start = moment(startTime, 'HH:mm');
    const finish = moment(finishTime, 'HH:mm');

    const totalMBMinutes = moment.duration(totalMBDed).asMinutes();

    if (finish.isBefore(start)) {
      finish.add(1, 'day');
    }
  
    let durationMinutes = moment.duration(finish.diff(start)).asMinutes();

    durationMinutes -= totalMBMinutes;

    const isNegative = durationMinutes < 0;

    const absoluteDurationMinutes = Math.abs(durationMinutes);

    const hours = Math.floor(absoluteDurationMinutes / 60);
    const minutes = Math.floor(absoluteDurationMinutes % 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return isNegative ? `-${formattedHours}:${formattedMinutes}` : `${formattedHours}:${formattedMinutes}`;
  };

  useEffect(() => {
    setSelectedHour(value.split(':')[0]);
    setSelectedMinute(value.split(':')[1]);
    setTypeFieldInput( value !== "00:00" ? value : "")
  }, [value]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleSelectHour = (hour: string, minute?: string) => {
    setSelectedHour(hour);
    setWeekData && setWeekData((prevWeekData: DailyTime[]) => {
      const newWeekData: DailyTime[] = [...prevWeekData];
      const time = `${hour}:${minute || selectedMinute}`;

      if (newWeekData[rowIndex]) {
        const { Start, Finish, TotalMBDed } = newWeekData[rowIndex];

        let updatedStart = Start;
        let updatedFinish = Finish;
        let updatedTotalMBDed = TotalMBDed;

        if (fieldKey === 'Start') {
          updatedStart = time;
        } else if (fieldKey === 'Finish') {
          updatedFinish = time;
        } else if (fieldKey === 'TotalMBDed') {
          updatedTotalMBDed = time;
        }

        const totalHours = calculateTotalHours(
          updatedStart,
          updatedFinish,
          updatedTotalMBDed,
        );

        newWeekData[rowIndex] = {
          ...newWeekData[rowIndex],
          [fieldKey]: time,
          TotalHours: totalHours,
        };

        if (newWeekData[rowIndex]?.Payas === '0') {
          newWeekData[rowIndex] = {
            ...newWeekData[rowIndex],
            Payas: 'Worked',
            customNote: 'Worked ',
          };
        }
      }

      return newWeekData;
    });
  };

  const handleSelectMinute = (minute: string, hour?: string) => {
    setSelectedMinute(minute);
    setWeekData && setWeekData((prevWeekData: DailyTime[]) => {
      const newWeekData: DailyTime[] = [...prevWeekData];
      const time = `${hour || selectedHour}:${minute}`;

      if (newWeekData[rowIndex]) {
        const { Start, Finish, TotalMBDed } = newWeekData[rowIndex];

        let updatedStart = Start;
        let updatedFinish = Finish;
        let updatedTotalMBDed = TotalMBDed;

        if (fieldKey === 'Start') {
          updatedStart = time;
        } else if (fieldKey === 'Finish') {
          updatedFinish = time;
        } else if (fieldKey === 'TotalMBDed') {
          updatedTotalMBDed = time;
        }

        const totalHours = calculateTotalHours(
          updatedStart,
          updatedFinish,
          updatedTotalMBDed,
        );

        newWeekData[rowIndex] = {
          ...newWeekData[rowIndex],
          [fieldKey]: time,
          TotalHours: totalHours,
        };
      }

      return newWeekData;
    });
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event?.target?.value;
    
    if(typeof event === 'string') {
      value = event
    }
    
    setTypeFieldInput(value);

    if (value === "") return;

    let isValid = true;
    if (value.length > 5) {
      isValid = false;
    } else {
      let timeParts = value.split(/[:,.]/);
      if (timeParts.length > 2) {
        isValid = false;
      } else if (timeParts.length ===1 && Number(value) >= 0 && Number(value) <= 23) {
        value = ("00" + value).slice(-2);
        value = value + ":00";
      } else if (timeParts.length === 1) {
        value = ("0000" + value).slice(-4);
        value = value.substring(0, 2) + ":" + value.substring(2, value.length);
      } else {
        value =
          ("00" + timeParts[0]).slice(-2) +
          ":" +
          ("00" + timeParts[1]).slice(-2);
      }
    }

    if (alertTimeout.current) {
      clearTimeout(alertTimeout.current);
    }

    alertTimeout.current = setTimeout(() => {
      if (!moment(value, "HH:mm", true).isValid() || !isValid) {
        setSelectedHour("00");
        setSelectedMinute("00");
        setTypeFieldInput("");
        alert("Time string is not valid.");
        return;
      } else {
        if(onChange && isTimeSheet) {
          onChange(moment(value, 'HH:mm'))
        }
        setTypeFieldInput(value)
        const formattedTime = moment(value, "HH:mm").format("HH:mm");
        const [hour, minute] = formattedTime.split(":");
    
        setSelectedHour((prevHour : string) => {
          if(prevHour !== hour) {
            handleSelectHour(hour, minute)
          }
          return hour
        });
        setSelectedMinute((prevMinute : string) => {
          if(prevMinute !== minute) {
            handleSelectMinute(minute, hour)
          }
          return minute
        });
      }
    }, 1000);
  };

  const RenderTextInput = () => {
    if(isTimeSheet) {
      return <TextInput value={typeFieldInput} onChange={handleInputChange} label={label} />
    } else {
      return <TextTimeInput
            isTimeSheet={isTimeSheet}
            type="text"
            value={typeFieldInput}
            onChange={handleInputChange}
            placeholder="00:00"
          />
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="relative" display="inline-block" width={'100%'}>
        {timeOption === TimeOption.DropList ? (
          <Box
            component="span"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
              borderRadius: '4px',
              backgroundColor: open ? brandColour?.primary20 : 'white',
            }}
          >
            {`${selectedHour}:${selectedMinute}`}
          </Box>
        ) : (
          RenderTextInput()
        )}
        {open && timeOption && (
          <Box
            display="flex"
            position="absolute"
            top="100%"
            left="0"
            zIndex={100000}
          >
            <TimePickerDropdown>
              {hours.map((hour) => (
                <StyledMenuItem
                  key={hour}
                  onClick={() => handleSelectHour(hour)}
                >
                  {hour}
                </StyledMenuItem>
              ))}
            </TimePickerDropdown>
            <TimePickerDropdown>
              {minutes.map((minute) => (
                <StyledMenuItem
                  key={minute}
                  onClick={() => handleSelectMinute(minute)}
                >
                  {minute}
                </StyledMenuItem>
              ))}
            </TimePickerDropdown>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default timeInputField;
