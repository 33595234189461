import { TimePickerProps } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useState } from 'react';
import {
  StyledContainer,
  StyledInputContainer,
  StyledInputText,
  StyledTimePicker,
  StyledError,
  StyledCloseContainer,
} from './styles';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { fontSize } from '@tphglobal/common/theme/style.typography';
import { greyScaleColour } from '@tphglobal/common/theme/style.palette';

type TimePickerCustomProps = TimePickerProps<any> & {
  error?: string;
  disableErrorMode?: boolean;
  required?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  slots?: any;
  minTime?: any;
  maxTime?: any;
  disableTime?: boolean;
  onChange?: any;
  clearable?: boolean;
  disableDropDown?: boolean;
  CustomTimeField?: any
};

const iconStyles = {
  height: fontSize?.h3,
  width: fontSize?.h3,
  color: greyScaleColour?.black100,
};

const TimeIconComponent = (onChange: any, clearable: boolean, value: any) => (
  <>
    {clearable && value && (
      <StyledCloseContainer
        onClick={(event: any) => {
          event.stopPropagation();
          onChange(null);
        }}
      >
        <CloseIcon style={iconStyles} />
      </StyledCloseContainer>
    )}
    <WatchLaterOutlinedIcon style={iconStyles} />
  </>
);

const TimeInput = ({
  label,
  readOnly,
  disabled,
  minTime,
  maxTime,
  disableTime = false,
  onChange,
  clearable,
  disableDropDown,
  CustomTimeField,
  ...props
}: TimePickerCustomProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    if (!disableDropDown) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <StyledContainer>
      <StyledInputContainer>
        {CustomTimeField ? (
          <CustomTimeField label={label} value={props?.value ? moment(props?.value).format('HH:mm') : '00:00'} onChange={onChange} timeOption={'Type'} isTimeSheet={true} />
        ) : (
          <StyledTimePicker
            {...props}
            disableDropDown={disableDropDown}
            value={props?.value || null}
            ampm={false}
            format="HH:mm"
            fullWidth={props.fullWidth}
            disabled={disabled}
            label={label}
            minTime={minTime && moment(minTime).add(1, 'minute')}
            maxTime={maxTime && moment(maxTime).subtract(1, 'minute')}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            slots={{
              openPickerIcon: () =>
                TimeIconComponent(onChange, clearable, props?.value),
            }}
            onChange={onChange}
            slotProps={{
              actionBar: {
                style: { justifyContent: 'center' },
              },
            }}
            shouldDisableTime={(timeValue: any, clockType: string) => {
              if (disableTime) {
                const hours = new Date(timeValue).getHours();
                const minutes = new Date(timeValue).getMinutes();
                return clockType === 'minutes' && hours === 0 && minutes === 0;
              }
              return false;
            }}
          />
        )}
      </StyledInputContainer>
      {!props.disableErrorMode && props.error && (
        <StyledError variant="body2">{props.error || ''}</StyledError>
      )}
    </StyledContainer>
  );
};

export default TimeInput;
