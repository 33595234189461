import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FormError,
  FormRow,
  FormRowItem,
  MaterialAutocompleteInput,
  OutlinedContainer,
  TextInput,
  Toast,
} from '@tphglobal/components';
import {
  brand,
  brandColour,
  otherColour,
} from '@tphglobal/common/theme/style.palette';
import { Grid } from '@mui/material';
import { fontSize, fontWeight } from '@tphglobal/common/theme/style.typography';
import { useDispatch, useSelector } from 'react-redux';
import { useFormReducer } from '@tphglobal/common/hooks';
import {
  emailValidator,
  FixedTitle,
  HttpMethods,
} from '@tphglobal/common/utils';
import CachedIcon from '@mui/icons-material/Cached';
import { useLocation } from 'react-router-dom';
import useWindowSize from '@tphglobal/common/hooks/windowSize';
import { breakpoints } from '@tphglobal/common/theme/style.layout';
import { RegionTypes } from '@tphglobal/common/utils/constants';
import { toast } from 'react-toastify';
import { goBack, push } from 'connected-react-router';
import { Container } from '../../components';
import handleApiCall from '../handleApiCall';
import { ReduxState } from '../../redux/reducers';
import { CompanyDetailedEntity, CompanyEntity } from '../../models';
import { required, routes } from '../../utils';
import {
  ADD_COMPANY_AU,
  ADD_COMPANY_UK,
  CREATE_ACTIVITY_LOGS_AU,
  CREATE_ACTIVITY_LOGS_UK,
  GET_SYS_COMPANY_BY_ID_AU,
  GET_SYS_COMPANY_BY_ID_UK,
  UPDATE_COMPANY_AU,
  UPDATE_COMPANY_UK,
} from '../../api';
import {
  Column,
  StyledColumn,
  StyledError,
  StyledHeading,
  StyledRow,
  StyledSearch,
} from './styles';
import messages from '../../messages';
import { getIPAddress, mapDepartmentNameToOption } from '../../utils/commonFunctions';

const validators = {
  position: [required('')],
  department: [required('')],
  email: [emailValidator],
};

const iconStyles = {
  height: fontSize?.h5,
  width: fontSize?.h5,
  color: brandColour?.primary100,
};

interface SubmitData {
  companyId: string;
  department: string;
  departments : {id: string, label : string}
  email: string;
  position: string;
}

const AddCompany = () => {
  const reduxDispatch = useDispatch();
  const selectedRegion = useSelector(
    (state: ReduxState) => state?.profile?.region,
  );
  const [fetchError, setFetchError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const {
    connectField, change, formValues, handleSubmit,
  } = useFormReducer(validators);
  const [companyData, setCompanyData] = useState<CompanyDetailedEntity | null>(
    null,
  );

  interface CompanyData {
    id: string;
    EmailAddress: string;
    Dept: string;
    Position: string;
    Guid: string;
    SysCoyGuid: string;
  }

  interface LocationStateData {
    isUpdate: boolean;
    data: CompanyData;
    companies: CompanyEntity[];
  }

  interface LocationState {
    data: LocationStateData;
  }
  const location = useLocation<LocationState>();

  const { width } = useWindowSize();

  const isMobile = width <= breakpoints.mobile;
  const isUpdate = location?.state?.data?.isUpdate;
  const companyPassedData = location?.state?.data?.data;
  const addedCompanies = location?.state?.data?.companies;
  const [submitError, setSubmitError] = useState<string>('')

  const handleFetchDetails = (companyId?: string) => {
    const companyGuid = companyId || formValues?.companyId?.value?.trim();
    if (companyGuid === '') {
      setFetchError(messages?.company?.form?.validators?.companyIdInValid);
    } else if (
      addedCompanies?.find(
        (company: CompanyEntity) => company?.SysCoyGuid === companyGuid,
      )
    ) {
      toast(<Toast type="error" text={messages?.company?.fetchError} />);
    } else if (companyId || formValues?.companyId?.value) {
      setFetchError('');
      handleApiCall(
        reduxDispatch,
        `${
          selectedRegion === RegionTypes.AU
            ? GET_SYS_COMPANY_BY_ID_AU
            : GET_SYS_COMPANY_BY_ID_UK
        }/${companyGuid}`,
        (companyObj) => {
            setCompanyData(companyObj?.result);
        },
        HttpMethods?.GET,
        selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
        null,
        null,
        setFetchError
      );
    } else {
      setFetchError(messages?.company?.form?.validators?.companyIdRequired);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      change('department', companyPassedData?.Dept);
      change('departments', companyPassedData?.Dept)
      change('position', companyPassedData?.Position);
      change('email', companyPassedData?.EmailAddress);
      handleFetchDetails(companyPassedData?.id);
    }
  }, []);

  const handleCreateActivityLog = async () => {
    const ipAddress = await getIPAddress();

    const sanitizedBody = {
      Description: `${companyData?.Name} has been ${isUpdate ? 'updated' : 'created'}.`,
      IPAddress: ipAddress,
      Type: companyData?.Name,
    };
    handleApiCall(
      reduxDispatch,
      selectedRegion === RegionTypes.AU
        ? CREATE_ACTIVITY_LOGS_AU
        : CREATE_ACTIVITY_LOGS_UK,
      () => {
        reduxDispatch(push(routes?.company?.root));
        setSubmitting(false);
      },
      HttpMethods?.POST,
      selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
      null,
      sanitizedBody,
    );
  };

  const onSubmit = async (data?: SubmitData): Promise<void> => {    
    let sanitizedBody = {
      Name: companyData?.Name,
      Production: companyData?.Production,
      TaxID: companyData?.TaxID?.toString(),
      EmailAddress: data?.email,
      Add1: companyData?.Add1,
      Add2: companyData?.Add2,
      State: companyData?.State,
      PCode: companyData?.PostCode,
      Country: companyData?.Country,
      PayFreq: companyData?.PayFrequency,
      PayEnding: companyData?.PayEnding,
      Dept: companyData?.departments ?  data?.departments?.label : data?.department?.trim(),
      Position: data?.position?.trim(),
      SysCoyGuid: formValues?.companyId?.value,
    };
    setSubmitting(true);
    if (isUpdate) {
      sanitizedBody = {
        ...sanitizedBody,
        SysCoyGuid: companyPassedData?.SysCoyGuid,
      };
    }

    if (isUpdate) {
      handleApiCall(
        reduxDispatch,
        (selectedRegion === RegionTypes.AU
          ? UPDATE_COMPANY_AU
          : UPDATE_COMPANY_UK
        ).concat(`/${companyPassedData?.Guid}`),
        () => {
          reduxDispatch(push(routes?.company?.root));
          handleCreateActivityLog();
          setSubmitting(false);
        },
        HttpMethods?.PUT,
        selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
        messages?.company?.form?.success?.addSuccess,
        sanitizedBody,
        setSubmitError,
        () => {
          setSubmitting(false)
        }
      );
    } else {
      handleApiCall(
        reduxDispatch,
        selectedRegion === RegionTypes.AU ? ADD_COMPANY_AU : ADD_COMPANY_UK,
        () => {
          reduxDispatch(push(routes?.company?.root));
          handleCreateActivityLog();
        },
        HttpMethods?.POST,
        selectedRegion === RegionTypes.AU ? RegionTypes.AU : RegionTypes.UK,
        messages?.company?.form?.success?.addSuccess,
        sanitizedBody,
        setSubmitError,
      );
    }
  };
  return (
    <Container
      showGoBack
      heading={
        messages?.company?.form?.[
          isUpdate ? 'mobileEditHeading' : 'mobileHeading'
        ]
      }
      background={otherColour?.adminBg100}
      maxHeight
    >
      <Card
        noContentPadding={isMobile}
        removeTitle
        contentCss={isMobile && { padding: '8px 16px !important' }}
        showGoBack
        header={(
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <StyledHeading
                fontSize={fontSize?.h2}
                fontWeight={fontWeight?.semiBold}
              >
                {isUpdate
                  ? messages?.company?.form?.editHeading
                  : messages?.company?.form?.heading}
              </StyledHeading>
            </Grid>
          </Grid>
        )}
      >
        {!isUpdate && (
          <OutlinedContainer
            borderRadius="0"
            padding="16px 0"
            noBorder
            styles={{
              borderTop: `1px solid ${brandColour?.primary10}`,
              borderBottom: `1px solid ${brandColour?.primary10}`,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledHeading
                  fontSize={fontSize?.h4}
                  lineHeight="26.04px"
                  fontWeight={fontWeight?.semiBold}
                >
                  {messages?.company?.form?.subHeading}
                </StyledHeading>
              </Grid>
              {!companyData ? (
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  {connectField('companyId', {
                    label: messages?.company?.form?.companyId,
                  })(TextInput)}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <StyledColumn cursor onClick={() => setCompanyData(null)}>
                    <StyledRow>
                      <FixedTitle varient="Fixed14Bold">
                        {messages?.company?.form?.companyId}
                      </FixedTitle>
                      <Grid display="flex" alignSelf="center">
                        <CachedIcon style={iconStyles} />
                        <FixedTitle
                          varient="Fixed14Regular"
                          color={brandColour?.primary100}
                        >
                          {messages?.general?.change}
                        </FixedTitle>
                      </Grid>
                    </StyledRow>
                    <FixedTitle varient="Fixed14Regular">
                      {formValues?.companyId?.value}
                    </FixedTitle>
                  </StyledColumn>
                </Grid>
              )}
              {!companyData && (
                <Grid item xs={12} sm={3} md={3} lg={3} alignSelf="center">
                  <StyledSearch onClick={() => handleFetchDetails()}>
                    <CachedIcon style={iconStyles} />
                    <FixedTitle
                      varient="Fixed14Regular"
                      color={brandColour?.primary100}
                    >
                      {messages?.company?.form?.fetchDetails}
                    </FixedTitle>
                  </StyledSearch>
                </Grid>
              )}
            </Grid>
            {fetchError && (
              <Grid item xs={12} paddingTop={0}>
                <StyledError variant="body2">{fetchError}</StyledError>
              </Grid>
            )}
          </OutlinedContainer>
        )}

        {(isUpdate || companyData) && (
          <>
            <OutlinedContainer
              noBorder
              padding="0"
              styles={{ marginTop: '16px' }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledHeading
                    fontSize={fontSize?.h4}
                    lineHeight="26.04px"
                    fontWeight={fontWeight?.semiBold}
                  >
                    {messages?.company?.details?.heading}
                  </StyledHeading>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    display="flex"
                    justifyContent="space-between"
                    xs={12}
                    container
                    spacing={2}
                  >
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.companyName}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.Name}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.productionName}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.Production}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.taxId}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.TaxID}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.addressLine1}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.Add1}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    display="flex"
                    justifyContent="space-between"
                    xs={12}
                    container
                    spacing={2}
                  >
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.addressLine1}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.Add2}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.town}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.City}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.state}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.State}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.postCode}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.PostCode}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid display="flex" xs={12} container spacing={2}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.country}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.Country}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.payFrequency}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.PayFrequency}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <StyledColumn>
                        <FixedTitle varient="Fixed14Bold">
                          {messages?.company?.details?.payEnding}
                        </FixedTitle>
                        <FixedTitle varient="Fixed14Regular">
                          {companyData?.PayEnding}
                        </FixedTitle>
                      </StyledColumn>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </OutlinedContainer>

            <OutlinedContainer
              borderRadius="0"
              padding="16px 0"
              noBorder
              styles={{
                marginTop: '16px',
                borderTop: `1px solid ${brandColour?.primary10}`,
                borderBottom: `1px solid ${brandColour?.primary10}`,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledHeading
                    fontSize={fontSize?.h4}
                    lineHeight="26.04px"
                    fontWeight={fontWeight?.semiBold}
                  >
                    {messages?.company?.details?.editHeading}
                  </StyledHeading>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  {companyData?.departments ? connectField('departments', {
                    label: messages?.timeCard?.form?.department,
                    options: companyData?.departments?.map(
                      mapDepartmentNameToOption,
                    ),
                  })(MaterialAutocompleteInput) : connectField('department', {
                    label: messages?.company?.details?.department,
                  })(TextInput)}
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  {connectField('position', {
                    label: messages?.company?.details?.position,
                  })(TextInput)}
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  {connectField('email', {
                    label: messages?.company?.details?.email,
                  })(TextInput)}
                </Grid>
                
                <Grid item xs={12} marginTop={1}>
                {submitError && (
                  <FormRow >
                    <FormRowItem>
                      <FormError
                        message={submitError}
                      />
                    </FormRowItem>
                  </FormRow>
                )}
                  <Grid container>
                    <Grid
                      container
                      justifyContent={isMobile && 'flex-end'}
                      spacing={2}
                    >
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={handleSubmit(onSubmit)}
                          disabled={submitting}
                          label={messages?.general?.[isUpdate ? 'save' : 'add']}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          buttonColor={brand?.black}
                          color="primary"
                          size="large"
                          label={messages?.general?.cancel}
                          onClick={() => reduxDispatch(goBack())}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </OutlinedContainer>
          </>
        )}
      </Card>
    </Container>
  );
};

export default AddCompany;
